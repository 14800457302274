<template>
  <div class="writtenAi">
    <div>
      <div class="head_hd"></div>
      <div class="banner">
        <div>一键续写</div>
        <div @click="goshopp">领导讲话稿购买</div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Leadership from './Leadership.vue'

export default {
  name: 'writtenAi',
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {}
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    goshopp() {
      this.$router.push({
        path: '/Leadership',
        query: { id: 1 },
      })

      //   this.isLeadership = true
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.writtenAi {
  .head_hd {
    width: 100%;
    height: 260px;
    background: url('~assets/writtenAi/header.png') no-repeat;
    background-size: 100% 100%;
  }
  .banner {
    display: flex;
    justify-content: space-around;
    div {
      width: 200px;
      height: 38px;
      border: 1px solid #4587ff;
      border-radius: 4px;
      text-align: center;
      line-height: 38px;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}
</style>
