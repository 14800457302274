import crewordRequest from "./crewordRequest";
// ai写作列表;
export function getAiwritingList(data) {
  return crewordRequest({
    url: "/aiwriting/list",
    method: "post",
    data,
  });
}

// 上传word文件
export function aiwritingUpload(data) {
  return crewordRequest({
    url: "/aiwriting/upload",
    method: "post",
    data,
  });
}

// 删除word文件
export function aiwritingDel(data) {
  return crewordRequest({
    url: "/aiwriting/del",
    method: "post",
    data,
  });
}

// 生成副本
export function aiwritingCopy(data) {
  return crewordRequest({
    url: "/aiwriting/copy",
    method: "post",
    data,
  });
}

// 修改
export function aiwritingUpdate(data) {
  return crewordRequest({
    url: "/aiwriting/update",
    method: "post",
    data,
  });
}

// 回收站列表
export function aiwritingRecyclingList(data) {
  return crewordRequest({
    url: "/aiwriting/recyclingList",
    method: "post",
    data,
  });
}

// 还原word文件
export function aiwritingRecycling(data) {
  return crewordRequest({
    url: "/aiwriting/recycling",
    method: "post",
    data,
  });
}

//添加ai写作记录
export function aiwritingAdd(data) {
  return crewordRequest({
    url: "/aiwriting/add",
    method: "post",
    data,
  });
}

// 永久删除word文档
export function aiwritingClean(data) {
  return crewordRequest({
    url: "/aiwriting/clean",
    method: "post",
    data,
  });
}
// 永久删除word文档
export function aiwritingSyncCloud(data) {
  return crewordRequest({
    url: "/aiwriting/syncCloud",
    method: "post",
    data,
  });
}
