<template>
  <div class="writing">
    <!-- <div class="tit">
      <div class="butoon">AI成文</div>
    </div> -->
    <div class="writing1">
      <!-- <div class="head"></div> -->
      <div class="text">
        <div class="lefts">
          <div
            class="Continuation"
            :class="showContinua ? 'hove' : ''"
            @click="showContinuaclick"
          >
            <img
              src="../../assets/Continuation.png"
              alt=""
              v-if="!showContinua"
            />
            <img
              src="../../assets/Continuation1.png"
              alt=""
              v-if="showContinua"
            />
            一键续写
          </div>

          <div class="menu">
            <Menu :menuArr="dataList" />
          </div>
          <!-- <div class="rukou" @click="rukou">入口</div> -->
        </div>
        <div class="geli"></div>
        <div class="right">
          <div v-if="$route.query.id == 1"><router-view></router-view></div>
          <div
            class="shopping"
            v-if="
              !showContinua &&
              !shodilets &&
              !showBuy &&
              !writtenAi &&
              $route.query.id != 1
            "
          >
            <!-- 搜索 -->
            <div class="search_box" v-if="searchBox">
              <input
                class="inp"
                type="text"
                placeholder="成语AI-专业内容创作辅助"
                v-model="searchValue"
                @keyup.enter="search"
              />
              <div class="search_btn" @click="search()">
                <img :src="require('assets/addwen.png')" alt="" />
                <img
                  :src="require('assets/images/included_search.png')"
                  alt=""
                />
              </div>
            </div>
            <div class="header_box" v-if="types != 3 && searchBox">
              <div class="title">推荐内容：</div>
              <div @click="hotClick(1)">背叛国家罪</div>
              <div @click="hotClick(2)">分裂国家罪</div>
              <div @click="hotClick(3)">煽动分裂国家罪</div>
              <div @click="hotClick(4)">盗窃罪</div>
            </div>
            <div v-if="!searchBox" class="headee_img">
              <img src="../../assets/writtenAi/banner.png" alt="" />
            </div>
            <!-- 排序 -->
            <div class="list-title">
              <div
                class="list-title-item"
                v-for="(item, index) in sortArr"
                :key="index"
                style="cursor: pointer"
                @click="sortClick(item)"
              >
                <div
                  class="list-title-item-text"
                  :class="item.colorSwitch == true ? 'fontColor' : ''"
                >
                  {{ item.title }}
                </div>
                <i
                  class="list-title-icon up"
                  :class="item.sort == 'asc' ? 'up' : 'down'"
                ></i>
              </div>
            </div>
            <div class="context">
              <div
                class="item"
                v-for="(item, idx) in queryList"
                :key="idx"
                @click="OneclickwritingDilets(item)"
              >
                <div :class="item.url ? 'imgbox1' : 'imgbox'">
                  <div class="imgs">
                    <img src="../../assets/story.png" v-if="!item.url" alt="" />
                    <img
                      :src="`https://fafs.antiplagiarize.com/` + item.url"
                      v-if="item.url"
                      alt=""
                    />
                  </div>
                  <div class="img1">{{ item.title }}</div>
                </div>
                <div class="titlebox">
                  <div class="it">A I</div>
                  <div class="title">{{ item.title }}</div>
                </div>
                <div class="pase">
                  <span>{{ item.price }}</span
                  >券/篇
                  <span v-if="item.originalPrice" class="basx">{{
                    item.originalPrice
                  }}</span>
                </div>
              </div>
              <div class="shoppingcard" @click="shoppingcard">
                <div class="box">{{ num > 99 ? `${num}+` : num }}</div>
              </div>
            </div>
            <el-pagination
              class="pagination"
              background
              @current-change="currentChange"
              layout="prev, pager, next"
              :page-size="12"
              :total="totalNum"
            >
            </el-pagination>
          </div>
          <div class="Continua" v-if="showContinua && $route.query.id != 1">
            <Continua />
          </div>
          <div
            class="OneclickwritingDilets"
            v-if="shodilets && $route.query.id != 1"
          >
            <OneclickwritingDilets :valobj="valobj" />
          </div>
          <div
            class="OneclickwritingDilets"
            v-if="showBuy && $route.query.id != 1"
          >
            <BuyIng @back="back" />
          </div>
          <div
            class="OneclickwritingDilets"
            v-if="writtenAi && $route.query.id != 1"
          >
            <writtenAi @back="back" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Continua from "./Continuation.vue";
import OneclickwritingDilets from "./OneclickwritingDilets.vue";
import BuyIng from "./BuyIng.vue";
import writtenAi from "./writtenAi/writtenAi.vue";
import {
  choiceTreeList,
  queryProducts,
  readingCopyPageList,
  getList,
} from "api/Continuation";
import Menu from "components/menu/Menu";
import qs from "qs";
export default {
  name: "Continuation",
  //import引入的组件需要注入到对象中才能使用
  components: { Continua, Menu, OneclickwritingDilets, BuyIng, writtenAi },
  data() {
    //这里存放数据
    return {
      // 是否显示搜索
      searchBox: true,
      // 是否显示一键续写
      showContinua: true,
      shodilets: false,
      // 一键续写按钮显示
      imgshow: true,
      searchValue: "",
      totalNum: 0,
      showBuy: false,
      writtenAi: false,

      page: 1,
      labelId: "FZalgshi0001",
      types: 1,
      loading: false,
      valobj: {},
      sortType: 1, //排序类型
      sortVal: "desc", //排序类型
      num: 0,
      sortArr: [
        {
          title: "时间",
          sort: "desc",
          id: 1,
          colorSwitch: true,
        },
        {
          title: "价格",
          sort: "asc",
          id: 2,
          colorSwitch: false,
        },
        // {
        //   title: '新品',
        //   sort: 'asc',
        //   id: 3,
        // },
        // {
        //   title: '价格',
        //   sort: 'asc',
        //   id: 4,
        // },
        // {
        //   title: '评论数',
        //   sort: 'asc',
        //   id: 4,
        // },
      ],
      //   侧边栏数据
      dataList: [],
      queryList: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 获取购物车数据
    async getLists() {
      let data = await getList();
      this.num = 0;
      if (data.code == 200) {
        data.data.forEach((item) => {
          this.num += item?.esCases.length;
        });
      }
    },
    // 热门点击
    hotClick(id) {
      if (id == 1) {
        this.valobj = {
          id: 1,
          labelId: "FZalgshi0001-001-0001",
          price: "9.00",
          title: "背叛国家罪",
          type: 4,
        };
      } else if (id == 2) {
        this.valobj = {
          id: 2,
          labelId: "FZalgshi0001-001-0002",
          price: "9.00",
          title: "分裂国家罪",
          type: 4,
        };
      } else if (id == 3) {
        this.valobj = {
          id: 3,
          labelId: "FZalgshi0001-001-0003",
          price: "9.00",
          title: "煽动分裂国家罪",
          type: 4,
        };
      } else if (id == 4) {
        this.valobj = {
          id: 215,
          labelId: "FZalgshi0001-001-0215",
          price: "9.00",
          title: "盗窃罪 ",
          type: 4,
        };
      }
      this.shodilets = true;
    },
    back() {
      this.showContinua = false;
      this.shodilets = false;
      this.showBuy = false;
    },
    // 分页
    currentChange(val) {
      this.page = val;
      this.queryProduct({
        labelId: this.labelId,
        type: this.types,
        keyWords: this.searchValue,
        page: this.page,
        limit: 12,
      });
    },
    // 一键续写
    showContinuaclick() {
      this.$router.push("/Oneclickwriting");
      this.showContinua = true;
      this.shodilets = false;
      this.showBuy = false;
    },

    // 搜索
    search() {
      if (this.types == 3) {
        this.readingCopyPageLists();
      } else {
        let params = {
          labelId: this.labelId,
          type: this.types,
          keyWords: this.searchValue,
        };
        this.queryProduct(params);
      }
    },
    // 获取商品详情
    async queryProduct(params) {
      let data = await queryProducts(qs.stringify(params));
      if (data.code == 200) {
        if (data.data.data) {
          this.queryList = data.data.data;
        } else {
          this.queryList = data.data.books;
        }
        this.totalNum = data.data.total;
      } else {
        this.totalNum = 0;
        this.queryList = [];
      }
    },
    // 读书文案获取详情
    async readingCopyPageLists() {
      let params = {
        page: this.page,
        limit: 12,
        title: this.searchValue,
      };
      let data = await readingCopyPageList(params);
      if (data.code == 200) {
        this.totalNum = data.data.total;
        this.queryList = data.data.books;
      } else {
        this.totalNum = 0;
        this.queryList = [];
      }
    },
    // 去购物车
    shoppingcard() {
      this.$router.push({
        path: "/ShoppingCart",
        query: { id: 1, num: this.valobj.price, labelId: this.valobj.labelId },
      });
    },
    // 查看详情
    OneclickwritingDilets(val) {
      console.log(val);
      if (val.labelId.indexOf("AIGCgao0001") != -1) {
        this.$router.push({
          path: "/writtenAi",
          query: { id: 1, labelId: val.labelId },
        });
      } else {
        this.valobj = val;
        this.shodilets = true;
      }
    },
    // 排序
    sortClick(item) {
      this.sortArr.forEach((item) => {
        item.colorSwitch = false;
      });
      item.colorSwitch = !item.colorSwitch;
      if (item.sort == "desc") {
        item.sort = "asc";
      } else {
        item.sort = "desc";
      }
      this.sortType = item.id;
      this.sortVal = item.sort;
      this.queryProduct({
        labelId: this.labelId,
        type: this.types,
        keyWords: this.searchValue,
        page: this.page,
        limit: 12,
      });
    },
    // 获取侧边栏数据
    async getchoiceTreeList() {
      let data = await choiceTreeList();
      let resclone = (res) => {
        if (res.length > 0) {
          res.forEach((item) => {
            if (item.id == 1) {
              item.show = true;
            } else {
              item.show = false;
            }
            if (item.children && item.children.length > 0) {
              resclone(item.children);
            }
          });
        }
        return res;
      };
      const params = {
        id: 999,
        labelId: "",
        name: "已购买列表",
        parentId: 0,
        status: 1,
        type: 1,
        show: false,
      };

      // const params1 = {
      //   id: 888,
      //   labelId: '',
      //   name: '入口',
      //   parentId: 0,
      //   status: 1,
      //   type: 1,
      //   show: false,
      // }
      this.dataList = resclone(data.data);
      // this.dataList.splice(1, 0, params1)
      this.dataList.splice(1, 0, params);
    },
    // async getchoiceTreeList1() {
    //   let data = await choiceTreeList()
    //   data.data.forEach((item) => {
    //     if (item.id == 1) {
    //       item.show = true
    //     } else {
    //       item.show = false
    //     }
    //     if (item.children) {
    //       item.children.forEach((it) => {
    //         it.show = false
    //       })
    //     }
    //   })

    //   const params = {
    //     id: 999,
    //     labelId: '',
    //     name: '已购买列表',
    //     parentId: 0,
    //     status: 1,
    //     type: 1,
    //     show: false,
    //   }

    //   this.dataList = data.data
    //   this.dataList.splice(1, 0, params)
    // },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getLists();
    this.queryProduct({
      labelId: this.labelId,
      type: this.types,
      keyWords: this.searchValue,
      page: this.page,
      limit: 12,
    });
    this.getchoiceTreeList();
    this.$bus.$on("Oneclickwriting", (val) => {
      if (val.id == 2 || val.parentId) {
        this.searchBox = false;
      } else {
        this.searchBox = true;
      }
      this.labelId = val.labelId;
      this.types = val.type;
      // if (val.name == '读书文案') {
      //   this.readingCopyPageLists()
      // } else {
      this.queryProduct({
        labelId: this.labelId,
        type: this.types,
        keyWords: this.searchValue,
        page: this.page,
        limit: 12,
      });
      // }

      this.showContinua = false;
      this.shodilets = false;
      this.showBuy = false;
      this.writtenAi = false;
    });
    this.$bus.$on("showBuy", () => {
      // console.log(11);
      this.showContinua = false;
      this.shodilets = false;
      this.showBuy = true;
      this.writtenAi = false;
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  destroyed() {
    this.$bus.$off("Oneclickwriting");
  },
  beforeDestroy() {},
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后

  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
.writing {
  .writing1 {
    min-width: 800px;
    max-width: 1338px;
    box-sizing: border-box;
    margin: -30px auto 0;
  }
  // .tit {
  //   min-width: 800px;
  //   max-width: 1338px;
  //   height: 50px;
  //   // padding-left: 291px;
  //   margin: -30px auto 20px;
  //   border-bottom: 1px solid #f3f3f3;
  //   line-height: 50px;
  //   display: flex;
  //   align-items: center;
  //   .butoon {
  //     width: 72px;
  //     height: 24px;
  //     border-radius: 12px;
  //     background: linear-gradient(90deg, #ff6500, #ff9c00);
  //     font-size: 12px;
  //     line-height: 24px;
  //     text-align: center;
  //     color: #fff;
  //   }
  // }
  // .head {
  //   width: 100%;
  //   height: 10px;
  //   background: #f3f3f3;
  // }
  .text {
    width: 100%;
    height: calc(100vh - 70px);
    display: flex;
    .lefts {
      width: 210px;
      height: 100%;
      padding: 21px 18px 21px 20px;
      box-sizing: border-box;
      .rukou {
        font-size: 14px;
        color: #666;
        cursor: pointer;
      }
      .hove {
        background: linear-gradient(90deg, #ff6500, #ffae00) !important;
        color: #ffffff !important;
      }
      .Continuation {
        cursor: pointer;
        margin-bottom: 10px;
        width: 100%;
        height: 32px;
        background: #ffffff;
        border: 1px solid #ff6900;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ff7100;
        display: flex;
        align-items: center;
        img {
          width: 16px;
          margin-right: 9px;
        }
      }
      .menu {
        .el-menu {
          border: 0px;
          ::v-deep .el-menu-item-group__title {
            display: none;
          }
          ::v-deep .el-submenu__title {
            height: 46px;
            line-height: 46px;
          }
          .el-menu-item {
            height: 40px;
          }
        }
      }
    }
    .right {
      flex: 1;
      height: 100%;
      .shopping {
        // padding: 16px 54px;
        // box-sizing: border-box;
      }
      // 搜索
      .search_box {
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        border-radius: 3px;
        border: 1px solid #ff6900;
        width: 600px;
        margin: 16px auto 0;
        .inp {
          height: 26px;
          border-left: 1px solid #e4e4e4;
          padding-left: 20px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-text-gray;
          flex: 1;
        }
        .search_btn {
          height: 40px;
          padding: 0 10px;
          background: #ff6900;
          @include flex-center();
          // border-radius: 0 5px 5px 0;
          cursor: pointer;
          > img:first-child {
            width: 52px;
            height: 22px;
            margin: 0 10px;
          }
        }
      }
      .headee_img {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .header_box {
        width: 600px;
        margin: 0 auto;
        padding-right: 100px;
        box-sizing: border-box;

        display: flex;
        flex-wrap: wrap;
        margin-top: 12px;
        line-height: 20px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #999999;
        .title {
          font-weight: 600;
          font-size: 15px;
          color: #666;
        }
        div {
          cursor: pointer;
          margin-right: 20px;
        }
      }
      .list-title {
        display: flex;
        align-items: center;
        padding: 16px 54px;
        box-sizing: border-box;
        #LangeSelectNotes {
          margin-right: 25px;
        }
        &-item {
          display: flex;
          align-items: center;
          margin-right: 25px;
          .list-title-item-text {
            font-size: 14px;

            font-weight: 500;
            color: #333333;
          }
          .list-title-icon {
            width: 9px;
            height: 13px;
            margin-left: 6px;
            margin: 21px 6px;
            @include backgroundGroup(
              "../../assets/images/goldenSentence/sortIcon.png"
            );
          }
          .up {
            @include backgroundGroup(
              "../../assets/images/goldenSentence/sortIcon-blue-up.png"
            );
          }
          .down {
            @include backgroundGroup(
              "../../assets/images/goldenSentence/sortIcon-blue.png"
            );
          }
          .fontColor {
            color: #ff6900;
          }
        }
      }
      .context {
        width: 100%;
        height: calc(100vh - 410px);
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        padding: 16px 54px;
        box-sizing: border-box;
        .item {
          cursor: pointer;
          width: 220px;
          height: 300px;
          margin-right: 16px;
          margin-bottom: 30px;
          background: #ffffff;
          box-shadow: 0px 2px 12px 0px rgba(162, 170, 184, 0.22);

          .imgbox {
            position: relative;
            width: 100%;
            height: 215px;
            background: #ffffff;
            border: 1px solid #ebebeb;
            padding: 22px 24px;
            box-sizing: border-box;
            .imgs {
              width: 100%;
              height: 100%;
              // padding: 0 18px 0 28px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .imgs1 {
              width: 100%;
              height: 100%;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .img1 {
              position: absolute;
              top: 15px;
              width: 100%;
              // height: 100%;
              padding: 0 70px 0 38px;
              box-sizing: border-box;
              font-size: 16px;
              line-height: 20px;
              color: #fff;

              text-align: center;
              padding-top: 40px;
            }
          }
          .imgbox1 {
            position: relative;
            width: 100%;
            height: 215px;
            background: #ffffff;
            border: 1px solid #ebebeb;
            // padding: 22px 24px;
            box-sizing: border-box;
            .imgs {
              width: 100%;
              height: 100%;
              // padding: 0 18px 0 28px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .imgs1 {
              width: 100%;
              height: 100%;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .img1 {
              position: absolute;
              top: 15px;
              width: 100%;
              height: 100%;
              padding: 0 40px 0 40px;
              box-sizing: border-box;
              font-size: 16px;
              line-height: 20px;
              color: #fff;
              text-align: center;
              padding-top: 40px;
            }
          }
          .titlebox {
            width: 100%;
            padding: 20px 16px;
            box-sizing: border-box;
            display: flex;
            .it {
              width: 40px;
              height: 16px;
              background: #eb441e;
              border-radius: 2px;
              font-size: 14px;
              color: #ffffff;
              padding: 2px;
              box-sizing: border-box;
              margin-right: 12px;
              text-align: center;
            }
            .title {
              width: calc(100% - 30px);
              font-size: 16px;
              font-weight: bold;
              color: #333333;
              overflow: hidden; //超出隐藏
              white-space: nowrap; //不折行
              text-overflow: ellipsis; //溢出显示省略号
            }
          }
          .pase {
            font-size: 12px;
            color: #eb441e;
            padding-left: 16px;
            span {
              font-size: 16px;
              font-weight: bold;
              color: #eb441e;
            }
            .basx {
              text-decoration: line-through;
              font-weight: 400;
              font-size: 12px;
              color: #999;
              margin-left: 10px;
            }
          }
        }
      }
      .pagination {
        margin: 15px auto 0;
        text-align: center;
        ::v-deep .active {
          background-color: #ff6900 !important;
          color: #fff;
        }
      }
    }
    .geli {
      width: 5px;
      height: 100%;
      background: #f3f3f3;
    }
  }
  .shoppingcard {
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 150px;
    width: 51px;
    height: 51px;
    background: url("../../assets/shoppindcard.png") no-repeat;
    background-size: 100% 100%;
    .box {
      width: 100%;
      text-align: right;
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ff6500;
    }
  }
}
</style>
<style>
::-webkit-scrollbar {
  width: 2px; /*滚动条宽度*/
}
</style>
