
import store from '@/store/index.js'
import { aiwritingAdd, aiwritingUpdate } from 'api/index'
import { Message } from 'element-ui';
class MyButtonMenu {


  constructor() {
    this.title = '保存' // 自定义菜单标题
    this.iconSvg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
 <style type="text/css">
   .st0{fill:#4B5058;}
 </style>
 <path class="st0" d="M15.57,4.7c-0.03-0.16-0.11-0.31-0.23-0.43l-3.63-3.63c-0.11-0.11-0.27-0.19-0.44-0.23L1.86,0.4
   c-0.79,0-1.45,0.66-1.45,1.45v12.28c0,0.81,0.66,1.45,1.45,1.45h12.28c0.81,0,1.45-0.66,1.45-1.45V4.7H15.57z M10.61,9.93v4.08H5.4
   V9.93H10.61z M11.08,8.39H4.93c-0.75,0-1.12,0.44-1.12,1.32v4.31H1.99V1.99h2.95v3.18c0,0.81,0.56,1.36,1.36,1.36h3.4
   c0.81,0,1.36-0.56,1.36-1.36V2.31l2.95,2.95v8.76H12.2V9.7C12.2,8.83,11.83,8.39,11.08,8.39z M6.53,4.94V1.99h2.95v2.95H6.53z"/>
 </svg>
       `// 可选
    this.tag = 'button'
  }

  // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
  getValue(editor) {

    return ' hello '
  }

  // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
  isActive(editor) {

    return false
  }

  // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
  isDisabled(editor) {

    return false
  }

  // 点击菜单时触发的函数
  exec(editor, value) {

    if (this.isDisabled(editor)) return
    if (store.state.currentWord == null) {
      const params = {
        title: (editor.getText().substring(0, 20) || '未命名') + '.docx',
        content: editor.getHtml(),
        conciseText: editor.getText().substring(0, 20)
      }
      aiwritingAdd(params).then(res => {
        if (res.code == 200) {
          store.commit('setCurrentWord', res.data)
          Message.success('保存成功')
        }
      })
    } else {
      const params = {
        id: store.state.currentWord.id,
        userId: store.state.userInfo.id,
        title: store.state.currentWord.title,
        content: editor.getHtml(),
        conciseText: store.state.currentWord.conciseText,
      }
      aiwritingUpdate(params).then(res => {
        Message.success('保存成功')
      })
    }
  }

}

export default MyButtonMenu