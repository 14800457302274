
import html2canvas from "html2canvas"

class exportImg {

    constructor() {
        this.title = '导出成图片'
        this.iconSvg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
   <style type="text/css">
       .st0{fill:#2C3342;}
   </style>
   <g>
       <path class="st0" d="M4.67,4.08c-0.87,0-1.58,0.71-1.58,1.58c0,0.87,0.71,1.58,1.58,1.58c0.87,0,1.58-0.71,1.58-1.58
           C6.26,4.79,5.55,4.08,4.67,4.08z M4.9,5.66c0,0.12-0.1,0.22-0.23,0.22c-0.12,0-0.22-0.1-0.22-0.22c0-0.12,0.1-0.22,0.22-0.22
           C4.8,5.44,4.9,5.54,4.9,5.66z"/>
       <path class="st0" d="M14,1.8H2C1.17,1.8,0.8,2.17,0.8,3v10c0,0.83,0.37,1.2,1.2,1.2h12c0.83,0,1.2-0.37,1.2-1.2V3
           C15.2,2.17,14.83,1.8,14,1.8z M9.72,7.08C9.55,7.24,7.6,9.07,6.99,9.64C6.76,9.39,6.32,8.9,5.88,8.46
           C5.59,8.18,5.24,8.03,4.87,8.03c-0.14,0-0.29,0.02-0.44,0.06C4.08,8.2,3.84,8.38,3.8,8.42L2.2,9.6V3.2h11.6v6.1
           c-0.11-0.18-0.21-0.36-0.32-0.53c-0.4-0.67-0.78-1.31-1.12-1.67c-0.47-0.5-0.97-0.6-1.3-0.6C10.33,6.5,9.81,6.99,9.72,7.08z
            M2.2,11.11l2.46-1.64c0.02-0.02,0.1-0.06,0.16-0.08c0.05-0.01,0.06-0.01,0.1,0.03c0.52,0.52,1.11,1.17,1.22,1.3
           c0.07,0.09,0.33,0.37,0.76,0.4c0.22,0.02,0.55-0.03,0.87-0.35c0.12-0.12,1.87-1.75,2.71-2.54l0.21-0.19
           c0.06-0.06,0.24-0.19,0.38-0.19c0.1,0,0.2,0.06,0.32,0.18c0.33,0.35,0.94,1.43,1.47,2.38c0.35,0.63,0.69,1.22,0.95,1.63v0.74H2.2
           V11.11z"/>
   </g>
   </svg>
       `
        this.tag = 'button'
        this.showModal = true
        this.modalWidth = 300
    }

    // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
    getValue(editor) {
        return ' hello '
    }

    // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
    isActive(editor) {

        return false
    }

    // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
    isDisabled(editor) {

        return false
    }

    // 点击菜单时触发的函数
    exec(editor, value) {

        if (this.isDisabled(editor)) return
        const div = document.createElement("div");
        div.innerHTML = editor.getHtml();
        let fileName = div.querySelector("*:first-child").innerText || "未命名";
        fileName = fileName.substring(0, 21) + ".png";
        const editorDom = document.querySelector('.editor .w-e-scroll')
        console.log(editorDom);
        html2canvas(editorDom, {
            backgroundColor: 'white',
            useCORS: true,
            scale: 1,
            height: editorDom.scrollHeight + 200,
            windowHeight: editorDom.scrollHeight + 300
        }).then((canvas) => {
            let url = canvas.toDataURL("image/png");
            let aLink = document.createElement("a");
            aLink.style.display = "none";
            aLink.href = url;
            aLink.download = fileName;
            document.body.appendChild(aLink);
            aLink.click();
            document.body.removeChild(aLink);
        });

    }
}


export default exportImg