
class exportTXT  {    


    constructor() {
        this.title = '导出成TXT'
        this.iconSvg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
   <style type="text/css">
       .st0{fill:#999999;}
       .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#999999;}
   </style>
   <path class="st0" d="M12.28,4.43v9.77H2.72V1.8h7.05L12.28,4.43z M13.75,3.41l-3.01-3.16c-0.16-0.17-0.38-0.27-0.61-0.27H1.86
       C1.39-0.01,1,0.39,1,0.89v14.21c0,0.5,0.39,0.91,0.86,0.91h11.28c0.48,0,0.86-0.41,0.86-0.91V4.05C14,3.81,13.91,3.58,13.75,3.41z"
       />
   <rect x="5" y="5" class="st1" width="5" height="2"/>
   <rect x="6.39" y="5.08" class="st1" width="2.17" height="6.83"/>
   </svg>`
        this.tag = 'button'
        this.showModal = true
        this.modalWidth = 300
    }

    // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
    getValue(editor) {  
        return ' hello '
    }

    // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
    isActive(editor) { 

        return false
    }

    // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
    isDisabled(editor) { 

        return false
    }

    // 点击菜单时触发的函数
    exec(editor, value) {  

        if (this.isDisabled(editor)) return
        editor.insertText(value) // value 即 this.value(editor) 的返回值
    }
}


export default exportTXT