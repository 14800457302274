import { preserveWord } from 'api/common'
import store from '@/store/index.js'
import qs from 'qs'
class exportWord {
    constructor() {
        this.title = '导出成Word'
        this.iconSvg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
   <style type="text/css">
       .st0{fill:#4B5058;}
   </style>
   <g>
       <polygon class="st0" points="7.44,6.86 6.36,10.99 5.32,6.86 3.87,6.86 5.71,13.26 6.99,13.26 8.07,9.15 9.15,13.26 10.43,13.26 
           12.28,6.86 10.82,6.86 9.79,10.99 8.71,6.86 	"/>
       <path class="st0" d="M14.67,4.15c-0.03-0.14-0.1-0.27-0.2-0.38l-3.22-3.22c-0.11-0.11-0.48-0.38-0.74-0.37h-8
           c-0.74,0-1.2,0.75-1.2,1.45v12.53c0,0.71,0.58,1.29,1.29,1.29h10.82c0.71,0,1.29-0.58,1.29-1.29V4.15H14.67z M10.7,4.17V1.86
           l2.32,2.32H10.7z M13.3,5.58v8.6H2.7V1.58h6.6l0.09,3.32c0,0.41,0.33,0.74,0.75,0.74L13.3,5.58z"/>
   </g>
   </svg>`
        this.tag = 'button'
        this.showModal = true
        this.modalWidth = 300
    }

    // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
    getValue(editor) {
        return ''
    }

    // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
    isActive(editor) {

        return false
    }

    // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
    isDisabled(editor) {

        return false
    }

    // 点击菜单时触发的函数
    exec(editor, value) {

        if (this.isDisabled(editor)) return //如果当前编辑器禁用则不执行
        let fileName = ''
        if (store.state.currentWord == null) {
            const div = document.createElement("div");
            div.innerHTML = editor.getHtml();
            fileName = div.querySelector("*:first-child").innerText || "未命名";
            fileName = fileName.substring(0, 21) + ".docx";
        } else {
            fileName = store.state.currentWord.title
        }

        const data = {
            fileName,
            content: editor.getHtml(),
        };
        preserveWord(qs.stringify(data)).then(res => {
            const blob = new Blob([res], {
                type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            let objectUrl = URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = objectUrl;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        })
    }
}


export default exportWord