import { preservePDF } from 'api/common'
import store from '@/store/index.js'
import qs from 'qs'
class exportPDF {


    constructor() {
        this.title = '导出成PDF'
        this.iconSvg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
   <style type="text/css">
       .st0{fill:#4B5058;}
   </style>
   <g>
       <path class="st0" d="M5.6,6.39v7.26h1.52v-2.63h1.5c2.24,0,2.71-1.26,2.71-2.32c0-1.05-0.47-2.3-2.69-2.3H5.6z M8.56,7.75
           c0.45,0,0.77,0.08,0.96,0.23C9.71,8.12,9.8,8.35,9.8,8.69c0,0.35-0.09,0.59-0.27,0.73C9.33,9.58,9,9.65,8.56,9.65H7.13v-1.9H8.56z"
           />
       <path class="st0" d="M14.67,4.34c-0.03-0.14-0.1-0.27-0.2-0.38l-3.22-3.22c-0.11-0.11-0.49-0.39-0.74-0.37h-8
           c-0.74,0-1.2,0.75-1.2,1.45v12.54c0,0.71,0.58,1.29,1.29,1.29h10.82c0.71,0,1.29-0.58,1.29-1.29V4.34H14.67z M10.7,4.37V2.05
           l2.32,2.32H10.7z M13.3,5.77v8.6H2.7V1.77h6.6l0.09,3.32c0,0.41,0.33,0.74,0.75,0.74L13.3,5.77z"/>
   </g>
   </svg>
   `
        this.tag = 'button'
        this.showModal = true
        this.modalWidth = 300
    }

    // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
    getValue(editor) {
        return ' hello '
    }

    // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
    isActive(editor) {

        return false
    }

    // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
    isDisabled(editor) {

        return false
    }

    // 点击菜单时触发的函数
    exec(editor, value) {
        if (this.isDisabled(editor)) return //如果当前编辑器禁用则不执行
        let fileName = ''
        if (store.state.currentWord == null) {
            const div = document.createElement("div");
            div.innerHTML = editor.getHtml();
            fileName = div.querySelector("*:first-child").innerText || "未命名";
            fileName = fileName.substring(0, 21) + ".pdf";
        } else {
            fileName = store.state.currentWord.title.substring(0, store.state.currentWord.title.lastIndexOf('.')) + '.pdf'
        }

        const data = {
            fileName,
            content: editor.getHtml(),
        };
        preservePDF(qs.stringify(data)).then(res => {
            const blob = new Blob([res], {
                type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            let objectUrl = URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = objectUrl;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        })
    }
}


export default exportPDF