import { preserveWord } from 'api/common'
import store from '@/store/index.js'
import qs from 'qs'
class exportWord {
    constructor() {
        this.title = '导入Word'
        this.iconSvg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
   <style type="text/css">
       .st0{fill:#4B5058;}
   </style>
   <path class="st0" d="M15.51,4.19L15.51,4.19c-0.12-0.02-0.25-0.01-0.38-0.01H15c-0.4,0-0.8,0-1.18,0h-1.06l-2.22-2.84
       c-0.18-0.18-0.38-0.27-0.62-0.27H0.95c-0.53,0-0.89,0.37-0.89,0.88v12.1c0,0.52,0.37,0.88,0.89,0.88h12.03
       c0.45,0,0.81-0.28,0.89-0.7l2.07-9.43C15.97,4.48,15.8,4.23,15.51,4.19z M14.44,5.63l-1.97,7.85H1.56l1.97-7.85
       C3.53,5.63,14.44,5.63,14.44,5.63z M1.49,6.26V2.52h7.97c0,0,1.65,1.65,1.65,1.65c-0.14,0-0.29,0-0.43,0c-0.37,0-0.74,0-1.12,0
       c-0.51,0-1.02,0-1.52,0c-0.55,0-1.1,0-1.66,0c-0.51,0-1.01,0-1.52,0c-0.37,0-0.73,0-1.1,0c-0.36,0-0.74,0.02-1.11,0.01
       c-0.23,0-0.42,0.01-0.56,0.3L1.49,6.26z"/>
   </svg>`
        this.tag = 'button'
        this.showModal = true
        this.modalWidth = 300
    }

    // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
    getValue(editor) {
        return ''
    }

    // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
    isActive(editor) {

        return false
    }

    // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
    isDisabled(editor) {

        return false
    }

    // 点击菜单时触发的函数
    exec(editor, value) {

        if (this.isDisabled(editor)) return //如果当前编辑器禁用则不执行

        const input = document.querySelector('#uploadChecking')
        input.click()
        console.log(input);
    }
}


export default exportWord