<template>
  <div class="Continuation">
    <div class="text">
      <div class="header_hd">
        <div class="select-type">
          <div class="title">算法引擎:</div>
          <div class="dropDownLists">
            <dropDownList ref="dropList" :groupId="4" :showll="4" />
          </div>
          <!-- <button @click="test">续写</button> -->
          <!-- <el-select v-model="value" class="m-2" size="large">
            <el-option
              v-for="item in checkList"
              @click="changeCurrnetClassfiy(item)"
              :key="item.id"
              :label="item.conName"
              :value="item.id"
            />
          </el-select> -->
        </div>
        <div class="items">
          <span class="title">文章长度:</span>

          <div
            title="续写300字"
            @click="zishuClcik(300, 0)"
            :class="nubitem == 0 ? 'wit' : ''"
          >
            短
          </div>
          <div
            title="续写600字"
            @click="zishuClcik(600, 1)"
            :class="nubitem == 1 ? 'wit' : ''"
          >
            中
          </div>
          <div
            title="续写1000字"
            @click="zishuClcik(1000, 2)"
            :class="nubitem == 2 ? 'wit' : ''"
          >
            长
          </div>
        </div>
        <!-- <div class="back" @click="back">返回</div> -->

        <button class="buttons" @click="ContinuationClickk">开始续写</button>
      </div>
      <div class="banner">
        <div class="content">
          <div class="count">字数：{{ count }}</div>
          <Toolbar
            class="toolbar"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
          />
          <div class="upload" v-show="false">
            <input type="file" id="uploadChecking" @change="uploadChecking" />
          </div>
          <!-- 编辑器角标 -->
          <!-- <div class="jia_box">
            <div class="box_jia"></div>
            <div class="box_jia"></div>
          </div> -->
          <!-- 编辑区区域 -->
          <div class="editor-box">
            <!-- 悬浮操作框 -->
            <Editor
              class="editor"
              style="overflow-y: auto"
              v-model="html"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onChange="onChange"
              @onCreated="onCreated"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 检验成语券弹框 -->
    <!-- <ContinuationTips
      :Tipsmessage="currentTips"
      :isShow="isShow"
      :number="number"
      @UpLevelAfter="isShow = false"
    /> -->
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getProductListGroup,
  recommendRenewal,
  checkIdiomCoupon,
} from "api/Continuation";
import { createLoading } from "utils/utils";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { DomEditor, Boot } from "@wangeditor/editor";
import { aiwritingUpload } from "api/index";
import dropDownList from "components/drop-down-list/dropDownList";
// import ContinuationTips from 'components/ContinuationTips'
import { amendUpdate, settings } from "api/service";
import qs from "qs";
import exportWord from "./editorModel/exportWord";
import exportPDF from "./editorModel/exportPDF";
import exportTXT from "./editorModel/exportTXT";
import exportImg from "./editorModel/exportImg";
import importWord from "./editorModel/importWord";
import MyButtonMenu from "./editorModel/ButtonMenu";
export default {
  name: "Continuation",
  //import引入的组件需要注入到对象中才能使用
  components: {
    Editor,
    Toolbar,
    dropDownList,
    //  ContinuationTips
  },
  data() {
    //这里存放数据
    return {
      count: 0,
      editor: null,
      html: "",
      toolbarConfig: {
        //工具栏配置
        toolbarKeys: [
          "importWord",
          // "saveCurrentContent",
          {
            key: "group-export",
            title: "导出方式",
            iconSvg: `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#4B5058;}
</style>
<g>
	<path class="st0" d="M3.51,10.36v0.09c0,0.19,0,0.35,0.12,0.48c0.11,0.11,0.27,0.12,0.41,0.12h6.39l-0.57,0.84
		c-0.16,0.16-0.25,0.36-0.25,0.59s0.09,0.43,0.25,0.59c0.16,0.16,0.37,0.25,0.59,0.25s0.43-0.08,0.59-0.25l2.17-2.17
		c0.16-0.16,0.25-0.37,0.25-0.59s-0.09-0.43-0.25-0.59l-2.17-2.17c-0.33-0.33-0.86-0.33-1.19,0c-0.33,0.33-0.33,0.86,0,1.19
		l0.76,0.76H4.35C3.88,9.52,3.51,9.91,3.51,10.36z"/>
	<path class="st0" d="M15.59,4.7h-0.02c-0.03-0.16-0.11-0.31-0.23-0.43l-3.63-3.63c-0.12-0.11-0.27-0.19-0.44-0.23L1.85,0.4
		C1.06,0.4,0.4,1.06,0.4,1.85v12.28c0,0.81,0.66,1.46,1.45,1.46h12.28c0.81,0,1.45-0.66,1.45-1.46V4.93V4.7z M6.3,6.53h3.4
		c0.81,0,1.36-0.56,1.36-1.36V2.31l2.95,2.95v8.76H1.99V1.99h2.95v3.18C4.94,5.97,5.49,6.53,6.3,6.53z M6.53,4.94V1.99h2.95v2.95
		H6.53z"/>
</g>
</svg>`,
            menuKeys: ["exportWord", "exportPDF", "exportImg"],
          },

          "|",
          "undo",
          "redo",

          "|",
          "bold",
          "underline",
          "italic",
          "color",
          "clearStyle",
          {
            key: "group-shoujin",
            title: "缩进",
            iconSvg: `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
<g>
	<path d="M9.22,13.19v-1.54h9.27v1.54H9.22z M9.22,8.36V6.81h9.27v1.54H9.22z M1.51,18.99v-1.54h16.98v1.54H1.51z M1.51,2.55V1.01
		h16.98v1.54H1.51z M8.62,10.19L6.93,12.1c-0.1,0.1-0.21,0.14-0.34,0.14c-0.06,0-0.12-0.01-0.18-0.04c-0.18-0.08-0.3-0.25-0.3-0.45
		v-0.97H2.32c-0.25,0-0.45-0.2-0.45-0.46V9.39c0-0.25,0.2-0.47,0.45-0.47h3.78V7.96c0-0.19,0.13-0.37,0.3-0.44
		C6.59,7.45,6.8,7.49,6.95,7.64L8.6,9.52C8.79,9.71,8.79,10.01,8.62,10.19z"/>
</g>
</svg>
`,
            menuKeys: ["indent", "delIndent"],
          },
          {
            key: "group-table",
            title: "列表",
            iconSvg: `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
<path d="M6.38,17.62v-1.47h12.13v1.47H6.38z M6.38,10.26V8.8h12.13v1.47H6.38z M6.38,3.83V2.36h12.13v1.47H6.38z M3.64,15.78
	c-0.12-0.1-0.55-0.11-0.71,0.03c-0.08,0.07-0.13,0.2-0.15,0.39l-0.02,0.19h0.51c0.18,0,0.31-0.02,0.38-0.09
	c0.03-0.03,0.08-0.09,0.08-0.25C3.72,15.85,3.66,15.8,3.64,15.78z M4.72,17.09c0.18,0.19,0.27,0.46,0.27,0.78
	c0,0.47-0.16,0.85-0.48,1.15c-0.32,0.31-0.74,0.46-1.25,0.46c-0.47,0-0.88-0.13-1.19-0.42c-0.35-0.3-0.53-0.72-0.57-1.28l-0.02-0.3
	h1.22l0.01,0.27c0.01,0.2,0.07,0.35,0.17,0.44c0.22,0.19,0.61,0.18,0.81-0.01C3.76,18.09,3.8,18,3.8,17.88
	c0-0.23-0.08-0.3-0.11-0.32c-0.09-0.06-0.24-0.1-0.43-0.1H2.71v-1.02H1.56l0.03-0.3c0.05-0.5,0.23-0.9,0.55-1.17
	c0.29-0.27,0.68-0.41,1.15-0.41c0.47,0,0.86,0.12,1.16,0.37c0.31,0.25,0.47,0.61,0.47,1.06c0,0.38-0.13,0.68-0.37,0.9
	C4.62,16.95,4.68,17.01,4.72,17.09z M4.93,12.31H1.58v-0.28c0-0.5,0.15-0.94,0.47-1.3c0.15-0.2,0.47-0.47,0.97-0.81
	C3.24,9.78,3.42,9.62,3.52,9.5c0.13-0.14,0.2-0.3,0.2-0.47c0-0.15-0.02-0.26-0.09-0.32C3.58,8.66,3.46,8.63,3.29,8.63
	c-0.22,0-0.31,0.08-0.35,0.15C2.86,8.91,2.8,9.11,2.78,9.38L2.78,9.64h-1.2l0.01-0.28c0.01-0.53,0.17-0.98,0.48-1.3
	C2.37,7.69,2.8,7.5,3.31,7.5c0.45,0,0.84,0.14,1.15,0.42c0.3,0.28,0.45,0.65,0.45,1.11c0,0.43-0.15,0.83-0.45,1.17
	C4.29,10.4,4,10.63,3.57,10.93c-0.13,0.09-0.24,0.16-0.32,0.23h1.68C4.93,11.16,4.93,12.32,4.93,12.31z M2.77,5.25v-3.1
	C2.6,2.25,2.39,2.32,2.18,2.4L1.84,2.5V1.23l0.2-0.06C2.2,1.14,2.37,1.06,2.57,0.96C2.73,0.86,2.88,0.73,3,0.6l0.08-0.08h0.88v4.73
	H2.77z"/>
</svg>
`,
            menuKeys: ["numberedList", "bulletedList"],
          },
          {
            key: "group-justify",
            title: "对齐方式",
            iconSvg: `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
<g>
	<path d="M1.52,8.47V7.02h8.49v1.44H1.52z M1.52,12.98v-1.44h16.96v1.44H1.52z M1.52,17.5v-1.44h4.24v1.44H1.52z M1.52,3.95V2.5
		h16.96v1.44H1.52z"/>
</g>
</svg>`,
            menuKeys: [
              "justifyLeft",
              "justifyRight",
              "justifyCenter",
              "justifyJustify",
            ],
          },
          "|",
          // "headerSelect",
          "fontSize",
          // "fontFamily",
          "lineHeight",

          "|",
          {
            key: "group-more-style",
            title: "更多",
            iconSvg: `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#222A39;}
</style>
<g>
	<g>
		<path class="st0" d="M1.65,10c0,0.58,0.31,1.11,0.81,1.4c0.5,0.29,1.11,0.29,1.61,0c0.5-0.29,0.81-0.82,0.81-1.4
			c0-0.58-0.31-1.11-0.81-1.4c-0.5-0.29-1.11-0.29-1.61,0C1.96,8.89,1.65,9.42,1.65,10L1.65,10z M1.65,10"/>
		<path class="st0" d="M8.39,10c0,0.58,0.31,1.11,0.81,1.4c0.5,0.29,1.11,0.29,1.61,0c0.5-0.29,0.81-0.82,0.81-1.4
			c0-0.58-0.31-1.11-0.81-1.4c-0.5-0.29-1.11-0.29-1.61,0C8.7,8.89,8.39,9.42,8.39,10L8.39,10z M8.39,10"/>
		<path class="st0" d="M15.13,10c0,0.58,0.31,1.11,0.81,1.4c0.5,0.29,1.11,0.29,1.61,0c0.5-0.29,0.81-0.82,0.81-1.4
			c0-0.58-0.31-1.11-0.81-1.4c-0.5-0.29-1.11-0.29-1.61,0C15.43,8.89,15.13,9.42,15.13,10L15.13,10z M15.13,10"/>
	</g>
</g>
</svg>`,
            menuKeys: [
              "blockquote",

              "insertLink",
              "insertTable",
              "uploadImage",
              "uploadVideo",
            ],
          },
        ],
      },
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            // form-data fieldName ，默认值 'wangeditor-uploaded-image'
            fieldName: "file",
            server: "/api/common/upload", //上传地址
            // 单个文件的最大体积限制，默认为 2M
            maxFileSize: 5 * 1024 * 1024, // 5M
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ["image/*"],
            // 超时时间，默认为 10 秒
            timeout: 10 * 1000, // 5 秒
            customInsert(res, insertFn) {
              // res 即服务端的返回结果

              // 从 res 中找到 url alt href ，然后插入图片
              insertFn("https://fafs.antiplagiarize.com/" + res.data, "", "");
            },
            headers: {
              Authorization:
                "bearer " + JSON.parse(localStorage.getItem("auth_token")),
            },
          },
          uploadVideo: {
            // form-data fieldName ，默认值 'wangeditor-uploaded-image'
            fieldName: "file",
            server: "/api/common/upload", //上传地址
            // 单个文件的最大体积限制，默认为 2M
            maxFileSize: 5 * 1024 * 1024, // 5M
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ["video/*"],
            // 超时时间，默认为 10 秒
            timeout: 10 * 1000, // 5 秒
            customInsert(res, insertFn) {
              // res 即服务端的返回结果

              // 从 res 中找到 url alt href ，然后插入图片
              insertFn("https://fafs.antiplagiarize.com/" + res.data, "", "");
            },
            headers: {
              Authorization:
                "bearer " + JSON.parse(localStorage.getItem("auth_token")),
            },
          },
        },
      },
      mode: "simple", // or 'simple'
      currentTips: "",
      // 显示次数不足弹框
      isShow: false,
      // 语境算法数据
      checkList: [
        {
          id: 1,
          conName: "AI成文语料库算法引擎",
          labelId: "YJAIcwylksfyq00001",
        },
      ],
      // 语境算法默认选择
      value: 1,
      //  选择字数
      nubitem: 0,
      //   语境库labelId
      labelId: "YJAIcwylksfyq00001",
      // 续写字数
      wordNum: 300,
      textval: "",
      number: 0,
      textval1: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 返回
    back() {
      this.$parent.showContinua = false;
    },
    // 下拉选择
    changeCurrnetClassfiy(item) {
      this.labelId = item.labelId;
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      this.initCustomMenu();
    },
    onChange(editor) {
      // const toolbar = DomEditor.getToolbar(editor);
      // const curToolbarConfig = toolbar.getConfig();
      // console.log(editor.getAllMenuKeys());
      this.count = editor.getText().length;
    },
    initCustomMenu() {
      // exportWord'
      const exportWordModel = {
        key: "exportWord", // 定义 menu key ：要保证唯一、不重复（重要）
        factory() {
          return new exportWord();
        },
      };
      const exportPDFModel = {
        key: "exportPDF", // 定义 menu key ：要保证唯一、不重复（重要）
        factory() {
          return new exportPDF();
        },
      };
      const exportTXTModel = {
        key: "exportTXT", // 定义 menu key ：要保证唯一、不重复（重要）
        factory() {
          return new exportTXT();
        },
      };
      const exportImgModel = {
        key: "exportImg", // 定义 menu key ：要保证唯一、不重复（重要）
        factory() {
          return new exportImg();
        },
      };
      // importWord
      const importWordModel = {
        key: "importWord", // 定义 menu key ：要保证唯一、不重复（重要）
        factory() {
          return new importWord();
        },
      };
      // MyButtonMenu
      const MyButtonMenuModel = {
        key: "saveCurrentContent", // 定义 menu key ：要保证唯一、不重复（重要）
        factory() {
          return new MyButtonMenu();
        },
      };
      Boot.registerMenu(exportWordModel);
      Boot.registerMenu(exportPDFModel);
      Boot.registerMenu(exportTXTModel);
      Boot.registerMenu(exportImgModel);
      Boot.registerMenu(MyButtonMenuModel);
      Boot.registerMenu(importWordModel);
    },
    async uploadChecking(e) {
      // if (!getToken("auth_token")) {
      //   this.$message({
      //     message: "请先登录！",
      //     duration: 1500,
      //   });

      //   return window.loginFun();
      // }
      const file = e.target.files[0];
      const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (suffix != "docx" && suffix != "doc") {
        this.$message({
          type: "error",
          message: "请选择docx或doc文件",
          customClass: "short-message",
          duration: 2000,
        });
        return;
      }
      var fromData = new FormData();
      fromData.append("file", file);
      // fromData.append("type", 0);
      const loading = createLoading(".editor-box");
      const res = await aiwritingUpload(fromData);
      if (res.code == 200) {
        loading.close();
        // this.html = res.data.content;

        this.editor.selectAll();
        this.editor.deleteFragment();
        this.editor.dangerouslyInsertHtml(res.data.content);

        this.$message({
          type: "success",
          message: "上传成功！",
          customClass: "short-message",
          duration: 2000,
        });
      }
    },
    async ContinuationClickk() {
      // this.editor.focus(true)
      let selection = JSON.parse(JSON.stringify(this.editor.selection));
      if (this.editor.selection) {
        this.textval = this.editor.getSelectionText();
        if (!this.textval) {
          selection.anchor.offset = 0;
          this.editor.select(selection);
          this.textval = this.editor.getSelectionText();
          this.editor.deselect();
        }
      } else {
        const text = this.editor.getText();
        this.textval = text.substring(text.length - 100);
      }
      let closure;
      if (selection) {
        closure = JSON.parse(JSON.stringify(selection));
        closure.anchor.offset = closure.focus.offset;
      }

      const loading = createLoading(".right");
      setTimeout(() => {
        loading.close();
      }, 50000);
      let params = {
        keywords: this.textval,
        // labelId: this.labelId,
        wordNum: this.wordNum,
      };
      let data = await recommendRenewal(qs.stringify(params));
      if (data.code == 200) {
        let idx = 0;
        loading.close();
        if (closure) {
          this.editor.select(closure);
        } else {
          this.editor.focus();
        }
        let timer = setInterval(() => {
          if (idx == data.data.result.length) {
            clearInterval(timer);
          } else {
            if (data.data.result[idx] == "^") {
              this.editor.insertBreak();
              // this.editor.focus();
              idx++;
            } else {
              this.textId++;
              this.textval1 = data.data.last;
              this.editor.insertText(data.data.result[idx++]);
            }
          }
        }, 20);
      } else {
        loading.close();
      }
    },
    // 有成语券校验弹框的续写
    async ContinuationClickk1() {
      let res = await checkIdiomCoupon({
        type: 7,
        num: this.wordNum,
      });
      if (res.code != 9008) {
        if (!this.textval) {
          this.isShow = false;

          let data = await settings();
          if (data.data.aiImg == 1) {
            this.isShow = false;
            this.textval = this.editor.getText();

            if (this.textval) {
              let params = {
                keywords: this.textval,
                labelId: this.labelId,
                wordNum: this.wordNum,
              };
              let data = await recommendRenewal(qs.stringify(params));
              let idx = 0;
              let timer = setInterval(() => {
                if (idx == data.data.result.length) {
                  clearInterval(timer);
                } else {
                  this.textval = data.data.last;
                  this.editor.insertText(data.data.result[idx++]);
                }
              }, 20);
            } else {
              this.$message.error("请输入内容");
            }
          } else {
            if (this.wordNum == 300) {
              this.$bus.$emit("numbers", 0.1);
            } else if (this.wordNum == 600) {
              this.$bus.$emit("numbers", 0.2);
            } else {
              this.$bus.$emit("numbers", 0.3);
            }
            this.isShow = true;
          }
        }
      } else {
        this.currentTips = res.message;
        this.number = 0;
        this.isShow = true;
      }
    },
    // 获取语境算法
    async getContextualDatabase() {
      const form = new FormData();
      form.append("groupId", 4);
      let data = await getProductListGroup(form);
      data.data.forEach((item) => {
        if (item.id != 8) {
          item.children.forEach((it) => {
            if (it.id != 25067) {
              this.checkList.push(it);
            }
          });
        }
      });
    },
    // 点击选择字数
    zishuClcik(e, id) {
      this.wordNum = e;
      this.nubitem = id;
    },
    test() {
      let str =
        "啥大家立刻的吉萨考虑到撒娇打开了就开始懒得几十块来的几十块来的金凯利三的加考虑到阿刷卡机的^撒旦教了价来看待吉萨考虑到加适量的as建档立卡啥来得快^jsdakl圣诞节快乐圣诞节快乐阿萨德是的";
      let idx = 0;
      let timer = setInterval(() => {
        if (idx == str.length) {
          clearInterval(timer);
        } else {
          if (str[idx] == "^") {
            this.editor.insertBreak();
            // this.editor.focus();
            idx++;
          } else {
            this.editor.insertText(str[idx++]);
          }
        }
      }, 20);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.getContextualDatabase();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.$refs["dropList"].getLibGroup(4);
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后

  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
.Continuation {
  padding: 16px 24px;
  box-sizing: border-box;
  .text {
    min-width: 800px;
    max-width: 1338px;
    box-sizing: border-box;

    .header_hd {
      position: relative;
      width: 100%;
      display: flex;

      .select-type {
        display: inline-block;
        display: flex;
        // justify-content: space-around;
        width: 42%;
        .dropDownLists {
          padding: 1px 20px;
          border-radius: 4px;
          text-align: center;
          line-height: 38px;
          border: 1px solid #ff6900;
        }
        ::v-deep .dropDownLists {
          .dropDownList {
            .el-dropdown {
              .el-dropdown-link {
                span {
                  font-size: 14px;
                }
                img {
                  width: 16px;
                }
              }
            }
          }
        }
        .title {
          width: 80px;
          height: 30px;
          border-radius: 4px;
          font-size: 14px;
          line-height: 38px;
          text-align: center;
        }
        ::v-deep .el-select {
          width: 80%;
          color: #000 !important;
          .el-input__wrapper {
            border-radius: 4px;
          }
          .el-input__inner {
            border: 1px solid #ff6900;
          }
        }
        ::v-deep .el-select .el-input.is-focus .el-input__inner {
          border-color: #ff6900 !important;
        }
        ::v-deep .el-select .el-input__inner:focus {
          border-color: #ff6900 !important;
        }
      }

      .items {
        width: 40%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .title {
          width: 80px;
          height: 38px;
          border-radius: 4px;
          font-size: 14px;
          line-height: 38px;
          text-align: center;
        }
        div {
          cursor: pointer;
          padding: 12px 24px;
          border: 1px solid #ff6900;
          border-radius: 4px;
          text-align: center;
        }
        .wit {
          color: #ff6900;
        }
      }
      .back {
        position: absolute;
        right: 80px;
        top: 0;
        width: 68px;
        height: 38px;
        text-align: center;
        line-height: 38px;
        border: 1px solid #ccc;
        color: #333;
        border-radius: 4px;
        cursor: pointer;
      }
      .buttons {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        padding: 14px 22px;
        border-radius: 4px;
        background-color: #ff6900;
        color: #fff;
        border: none;
      }
    }
    .banner {
      height: calc(100vh - 160px);
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      margin-top: 30px;
      box-shadow: 0 2px 10px #ccc;
      .content {
        // width: 98%;
        background-color: #fff;
        height: calc(100% - 1px);

        padding: 0 10px;
        position: relative;
        .count {
          position: absolute;
          top: 12px;
          // transform: translateY(-50%);
          right: 50px;
          z-index: 99;
        }
        .upload {
          position: absolute;
          top: 12px;
          left: 85px;
          img {
            width: 16px;
            height: 16px;
          }
        }
        .toolbar {
          box-sizing: border-box;
          border-bottom: 1px solid #eee;
          background: #ccc !important;
          ::v-deep .w-e-bar-show {
            // flex-wrap: nowrap;
            // justify-content: center;
            .w-e-bar-item {
              margin: 0 8px;
              button {
                padding: 0 1px !important;
              }
            }
          }
        }
        .jia_box {
          display: flex;
          justify-content: space-between;
          padding: 20px 0 10px;
          .box_jia {
            width: 35px;
            height: 35px;
            border-right: 1px solid #ccc;
            border-bottom: 1px solid #ccc;

            &:nth-child(2) {
              width: 35px;
              height: 35px;
              border-right: 0px solid #000;
              border-bottom: 0px solid #000;
              border-left: 1px solid #ccc;
              border-bottom: 1px solid #ccc;
            }
          }
        }
        .editor-box {
          position: relative;
          height: calc(100% - 46px);
          // box-shadow: 0 2px 10px #ccc;
          .editor {
            height: 100%;
            ::v-deep .w-e-text-container {
              .w-e-scroll::-webkit-scrollbar {
                width: 0px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style>
::-webkit-scrollbar {
  width: 2px; /*滚动条宽度*/
}
</style>
