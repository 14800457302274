<template>
  <div class="ShoppingCart">
    <div class="tab_box">
      <div class="back">
        <img class="icon" src="../../assets/icon9.png" alt="" />
        <div @click="$emit('back')">返回</div>
      </div>

      <div
        class="tab_item"
        :class="index == tabIndex ? 'active' : ''"
        @click="tabIndex = index"
        v-for="(item, index) in tabList"
        :key="index"
      >
        {{ item.name }}
      </div>
    </div>

    <div class="AIcopywriting" v-if="tabIndex != 0">
      <div class="head">
        <h2>AI文案-已购买列表</h2>
      </div>
      <div class="banner">
        <!-- 排序 -->
        <div class="list-title">
          <div
            class="list-title-item"
            v-for="(item, index) in sortArr"
            :key="index"
            style="cursor: pointer"
            @click="sortClick(item)"
          >
            <div
              class="list-title-item-text"
              :class="item.colorSwitch == true ? 'fontColor' : ''"
            >
              {{ item.title }}
            </div>
            <i
              class="list-title-icon up"
              :class="item.sort == 'asc' ? 'up' : 'down'"
            ></i>
          </div>
          <div class="dropdown">
            <el-dropdown trigger="click" @command="handleCommand">
              <span class="el-dropdown-link">
                筛 选<img class="shaix" src="../../assets/shaix.png" alt="" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="0">投资性购买 </el-dropdown-item>
                <el-dropdown-item command="1">独占性购买 </el-dropdown-item>
                <el-dropdown-item command="2">折扣购买</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="content">
          <div class="item" v-for="(it, idx) in dataList" :key="idx">
            <div class="title">
              <div class="imgs">
                <img src="../../assets/story.png" alt="" />
              </div>
              <h3>{{ it.name }}</h3>
            </div>
            <div class="text">
              <div class="items" v-for="(item, idx) in it.buysCases" :key="idx">
                <div class="items1">
                  <div class="idx">{{ idx + 1 }}</div>
                  <div class="titl" @click="caseDetails(item)">
                    {{ item.title }}
                  </div>
                  <div class="goshopp">支付成语券：{{ item.payIdiom }} 券</div>
                </div>
                <div class="items1 items2">
                  <div>备注：{{ item.remark }}</div>
                  <div class="time">
                    支付时间： <img src="../../assets/times.png" alt="" />
                    {{ item.createTime.split(" ")[0] }}
                  </div>
                  <div class="download" @click="downloadClick(item)">
                    <img src="../../assets/images/download_blue.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fooder">
        <el-pagination
          class="pagination"
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
    </div>

    <div class="MyManuscript" v-else>
      <MyManuscript />
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getList, submit, buyList } from "api/Continuation";
import { preserveWord } from "api/common";
import qs from "qs";
import MyManuscript from "./MyManuscript";
export default {
  name: "ShoppingCart",
  //import引入的组件需要注入到对象中才能使用
  components: { MyManuscript },
  data() {
    //这里存放数据
    return {
      tabIndex: 0,
      tabList: [
        {
          name: "AIGC稿",
        },

        {
          name: "AI文案",
        },
      ],
      page: 1,
      limit: 10,
      total: 0,
      // 勾选内容
      checkedList: [],
      checkedList1: [],
      // 一共多少钱
      priceList: [],
      pric: 0,
      checked: false,
      checked1: false,
      dataList: [],
      num: 0,
      sortArr: [
        {
          title: "时间",
          sort: "desc",
          id: 1,
          type: "create_time",
          colorSwitch: true,
        },

        {
          title: "价格",
          type: "price",
          sort: "asc",
          id: 2,
        },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 下拉选择
    handleCommand(val) {
      console.log(val);
    },
    // 下载
    downloadClick(val) {
      const data = {
        fileName: val.title,
        content: val.fullText,
      };
      preserveWord(qs.stringify(data)).then((res) => {
        const blob = new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        let objectUrl = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = objectUrl;
        link.setAttribute("download", val.title);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      });
    },

    // 获取购物车数据
    async getLists() {
      const params = {
        page: this.page,
        limit: this.limit,
      };
      let res = await buyList(params);
      if (res.code == 200) {
        this.dataList = res.data.data;
        this.total = res.data.total;
      }
    },
    currentChange(page) {
      this.page = page;
      this.getLists();
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getLists();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
.ShoppingCart {
  .head {
    display: flex;
    align-items: center;
    padding: 31px 58px;
  }

  .tab_box {
    display: flex;
    align-items: center;
    padding: 30px 30px 0;
    .back {
      margin-right: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      font-size: 14px;
      color: #999999;
      .icon {
        width: 6px;
        height: 11px;
        margin-right: 11px;
      }
    }

    .tab_item {
      font-size: 14px;
      margin: 0 10px;
      padding: 5px 10px;
      cursor: pointer;
    }

    .active {
      background: linear-gradient(90deg, #ff6500, #ff9c00);
      border-radius: 12px;
      color: #fff;
    }
  }

  h2 {
    flex: 1;
    text-align: center;
    font-size: 16px;
  }
  .banner {
    position: relative;
    .dropdown {
      .el-dropdown-link {
        display: flex;
        align-items: center;
      }

      cursor: pointer;
      position: absolute;
      right: 0;
      top: 19px;
      height: 55px;
      .shaix {
        width: 17px;
        height: 14px;
        margin-left: 5px;
      }
    }
    .list-title {
      display: flex;
      align-items: center;
      padding-left: 62px;
      .adds {
        cursor: pointer;

        padding: 11px 17px;
        background: #ffffff;
        border: 1px solid #d6e5ff;
        border-radius: 3px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #4587ff;
        &:hover {
          background-color: #f3f7ff;
        }
      }
      #LangeSelectNotes {
        margin-right: 25px;
      }
      &-item {
        display: flex;
        align-items: center;
        margin-right: 25px;
        .list-title-item-text {
          font-size: 14px;

          font-weight: 500;
          color: #333333;
        }
        .list-title-icon {
          width: 9px;
          height: 13px;
          margin-left: 6px;
          margin: 21px 6px;
          @include backgroundGroup(
            "../../assets/images/goldenSentence/sortIcon.png"
          );
        }
        .up {
          @include backgroundGroup(
            "../../assets/images/goldenSentence/sortIcon-blue-up.png"
          );
        }
        .down {
          @include backgroundGroup(
            "../../assets/images/goldenSentence/sortIcon-blue.png"
          );
        }
        .fontColor {
          color: #ff6900;
        }
      }
      .infocompileIndex_btn {
        margin-right: 20px;
        display: flex;
        align-items: center;
        .introduce-checkBox-icon {
          cursor: pointer;
          display: inline-block;
          width: 16px;
          height: 16px;
          background-repeat: no-repeat;
          background-size: cover;
          background-image: url("../../assets/gouxu1.png");
          margin-right: 5px;
        }
        .checkBox-checked {
          background-image: url("../../assets/gouxu.png");
        }
      }
    }
    .content {
      width: 100%;
      height: 72vh;
      overflow-y: auto;
    }
    .items {
      position: relative;

      padding: 13px 93px;
      font-size: 14px;
      .items1 {
        display: flex;
        align-items: center;
      }
      .items2 {
        position: relative;
        margin: 10px;
        padding-left: 30px;
        color: #999999;
      }
      .download {
        width: 14px;
        position: absolute;
        right: -60px;
        top: 0px;
        cursor: pointer;
      }
      img {
        width: 16px;
        height: 15px;
        margin-right: 15px;
      }
      .idx {
        width: 30px;
        margin-left: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
      .titl {
        cursor: pointer;
        font-weight: 400;
        color: #000000;
        width: 200px;
        flex: 1;
        overflow: hidden; //超出隐藏
        white-space: nowrap; //不折行
        text-overflow: ellipsis; //溢出显示省略号
      }
      .goshopp {
        cursor: pointer;
        color: #ff6900;
      }

      .time {
        position: absolute;
        right: 0;
        top: 0;
        text-align: right;
        display: flex;
        align-items: center;
        padding-left: 5px;
        margin-right: -5px;
        box-sizing: border-box;
        // align-items: center;
        color: #999999;
        img {
          width: 12px;
          height: 12px;
          margin-right: 5px;
        }
      }
      .go {
        cursor: pointer;
        position: absolute;
        right: 100px;
        margin-right: 20px;
        padding: 11px 17px;
        background: #ffffff;
        border: 1px solid #ffe1cd;
        border-radius: 3px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ff6500;
        &:hover {
          background-color: #fffaf3;
        }
      }
      .add {
        cursor: pointer;
        position: absolute;
        right: 0;
        padding: 11px 17px;
        background: #ffffff;
        border: 1px solid #d6e5ff;
        border-radius: 3px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #4587ff;
        &:hover {
          background-color: #f3f7ff;
        }
      }
    }
    .item {
      .title {
        position: relative;
        width: 100%;
        height: 88px;
        background: #fffaf6;
        padding: 15px 65px 16px 59px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #333333;
        .imgs {
          width: 57px;
          height: 57px;
          background: #ffffff;
          border: 1px solid #ebebeb;
          padding: 5px;
          box-sizing: border-box;
          margin-right: 14px;

          img {
            // height: 100%;
            width: 100%;
          }
        }
        .introduce-checkBox-icon {
          cursor: pointer;
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-bottom: 2px;
          background-repeat: no-repeat;
          background-size: cover;
          background-image: url("../../assets/gouxu1.png");
          margin-right: 5px;
        }
        .checkBox-checked {
          background-image: url("../../assets/gouxu.png");
        }
      }
    }
  }
  .fooder {
    width: 100%;
    padding-top: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .pagination {
      flex: 1;
      text-align: center;
      ::v-deep .active {
        background-color: #ff6900 !important;
        color: #fff;
      }
    }
    .shopp {
      flex: 1;
      display: flex;
      justify-content: right;
      align-items: center;
      font-size: 13px;
      color: #999999;
      span {
        font-size: 16px;
        color: #ff6900;
        margin: 0 5px;
      }
      .zoj {
        margin-left: 20px;
      }
      .fuk {
        cursor: pointer;
        width: 105px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #ff6500;
        border-radius: 4px;
        color: #fff;
        margin: 0 30px;
      }
    }
  }
  .introduce-checkBox-icon {
    cursor: pointer;
    display: inline-block;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../assets/gouxu1.png");
    margin-right: 5px;
  }
  .checkBox-checked {
    background-image: url("../../assets/gouxu.png");
  }
}
</style>
