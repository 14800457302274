<template>
  <div class="tree-wrap">
    <div>
      <div
        v-for="(item, index) in menuArr"
        :key="index"
        style="color: red"
        @click="listItem(item)"
      >
        <tree-item :list="item"></tree-item>
      </div>
    </div>
  </div>
</template>

<script>
import TreeItem from './MenuItem'
import { getTitleList } from 'api/header.js'
export default {
  name: 'tree',
  components: {
    TreeItem,
  },
  props: {
    menuId: {
      default: 0,
    },
    menuArr: {
      default: [],
    },
  },
  watch:{
    menuArr(newVal){
      this.$bus.$emit('Oneclickwriting', newVal[0].children[0])
    },
    
  },
  data() {
    return {
      hasId: 1,
      childId: 1,
    }
  },
  created() {},
  methods: {
    listItem(res) {
      this.hasId = res.id
    },
  },
}
</script>
<style scoped>
.tree-wrap {
  width: 100%;

  /* padding: 0 50px; */
}
</style>
