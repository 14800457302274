<template>
  <div class="tree-item">
    <div
      @click="handleToggles(list)"
      class="tree-title"
      :class="list.show && !list.children ? 'clit' : ''"
    >
      <div>
        <div ref="listTitle" class="listTitle">
          {{ list.name }}
          <span v-if="list.children"
            ><img
              :class="rotate ? 'go' : ' aa'"
              src="../../assets/images/icon.png"
              alt=""
          /></span>
        </div>
      </div>
    </div>
    <div v-show="open" class="child-content">
      <tree-item
        id="treeItem"
        v-for="(item, index) in list.children"
        :list="item"
        :key="index"
        >{{ item.title }}</tree-item
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'TreeItem',
  props: {
    list: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      open: false,
      hasId: 0,
      rotate: false,
      clicktitle: 1,
      menuArr: [],
    }
  },
  computed: {
    hasChild() {
      return this.list.children && this.list.children.length
    },
  },
  created() {
    this.menuArr = this.$parent.menuArr
  },
  methods: {
    matchClcik(res, val) {
      if (res.length > 0) {
        res.forEach((item) => {
          if (item.id == val.id) {
            item.show = true
          } else {
            item.show = false
          }
          if (item.children && item.children.length > 0) {
            this.matchClcik(item.children, val)
          }
        })
      }
      return res
    },
    handleToggles(val) {
      console.log(val)
      if (window.location.href.indexOf('Oneclickwriting') == -1) {
        this.$router.push('/newWordS/addWord/Oneclickwriting')
        this.$bus.$emit('Oneclickwriting', val)
      }
      if (val.id == 999) {
        this.matchClcik(this.menuArr, val)
        this.$bus.$emit('showBuy')
        return
      }

      if (val.children) {
        val.show = !val.show
        this.$bus.$emit('Oneclickwriting', val)
        this.hasId = val.id
        this.rotate = !this.rotate
        if (this.hasChild) {
          this.open = !this.open
        }
      } else {
        this.$bus.$emit('Oneclickwriting', val)
        this.matchClcik(this.menuArr, val)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tree-item {
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  cursor: pointer;
  .cl {
    // background-color: #fffaf3;
    // border-right: 4px solid #ff6900;
    .listTitle {
      color: #ff6900 !important;
    }
  }
  .clit {
    background-color: #fffaf3;
    border-right: 4px solid #ff6900;
    .listTitle {
      color: #ff6900 !important;
    }
  }
  .tree-title {
    .listTitle {
      padding: 7px 0;
      font-size: 14px;
      color: #666;
      span {
        float: right;
        img {
          width: 12px;
          height: 6px;
        }
      }
    }
    .bg {
      border: none;
    }
  }
}
.child-content {
  margin-left: 10px;
  #treeItem {
    ::v-deep .tree-title {
      h4 {
        padding: 20px 0;
        border: none;
      }
    }
  }
}
.cl {
  color: #ff6900;
}
.aa {
  transition: all 0.5s;
}

.go {
  transform: rotate(-180deg);
  transition: all 0.5s;
}
</style>
